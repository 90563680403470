import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Image, Text, Box, Icon, Link, Section } from "@quarkly/widgets";
import { MdLocationOn, MdPhone, MdMailOutline } from "react-icons/md";
const defaultProps = {
	"padding": "80px 0 0 0",
	"quarkly-title": "Contacts-6",
	"background": "#e9f3ff",
	"id": "#contact"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"justify-content": "space-around",
			"lg-flex-direction": "column",
			"margin": "0px 0px 0px 0px",
			"lg-align-items": "center"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"src": "https://cleverusland.com/img/6.jpg",
			"display": "block",
			"width": "50%",
			"object-fit": "cover",
			"height": "100%",
			"lg-width": "100%",
			"sm-height": "280px",
			"margin": "0px 30px 0px 30px",
			"lg-margin": "0px 30px 30px 30px",
			"lg-height": "auto"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"width": "50%",
			"lg-width": "100%",
			"display": "flex",
			"flex-direction": "column",
			"justify-content": "center",
			"lg-margin": "0px 0px 50px 0px",
			"align-items": "flex-start",
			"padding": "0px 50px 0px 50px",
			"sm-padding": "0px 15px 0px 15px",
			"sm-margin": "0px 0px 0 0px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 10px 25px 0px",
			"color": "#22156d",
			"font": "normal 500 28px/1.2 --fontFamily-sansHelvetica",
			"padding": "0px 10px 0px 0px",
			"border-color": "#c9d0d7",
			"sm-border-width": 0,
			"children": "Завітайте до нас сьогодні"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 32px 0px",
			"font": "--lead",
			"color": "#8b9197",
			"children": "У Cleverus Land Tennis Courts ми прагнемо надати вам найкращі враження від тенісу. Незалежно від того, чи збираєтеся ви тут для швидкої гри, уроку чи турніру, ми тут, щоб підтримати вашу пристрасть до тенісу. Приєднуйтеся до нашої спільноти та дізнайтеся, чому тенісисти вважають за краще тенісистів."
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "grid",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-gap": "16px 24px",
			"margin": "0px 0 0 0",
			"lg-grid-template-columns": "repeat(2, 1fr)",
			"padding": "50px 50px 50px 50px",
			"background": "#0b70de",
			"align-items": "center",
			"lg-grid-gap": "36px 34px",
			"md-grid-template-columns": "1fr",
			"sm-padding": "35px 15px 35px 15px"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"flex-direction": "row",
			"border-color": "--color-lightD2",
			"border-radius": "4px",
			"align-items": "center"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "md",
			"icon": MdLocationOn,
			"size": "54px",
			"margin": "0px 20px 0 0px",
			"color": "#0b70de",
			"background": "--color-light",
			"padding": "12px 12px 12px 12px",
			"border-radius": "50%"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-start"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"color": "--light",
			"text-decoration-line": "initial",
			"font": "normal 500 22px/1.5 --fontFamily-sansHelvetica",
			"display": "block",
			"margin": "0px 0px 5px 0px",
			"children": "Лижвірська 40а"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0 0px",
			"font": "normal 300 16px/1.2 --fontFamily-sans",
			"text-align": "center",
			"color": "--light",
			"md-margin": "0px 0px 0px 0px",
			"children": <>
				Львів, Україна
			</>
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"flex-direction": "row",
			"border-color": "--color-lightD2",
			"border-radius": "4px",
			"align-items": "center"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"category": "md",
			"icon": MdPhone,
			"size": "54px",
			"margin": "0px 20px 0 0px",
			"color": "#0b70de",
			"background": "--color-light",
			"padding": "12px 12px 12px 12px",
			"border-radius": "50%"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-start"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "tel:073 111-45-15",
			"color": "--light",
			"text-decoration-line": "initial",
			"font": "normal 500 22px/1.5 --fontFamily-sansHelvetica",
			"display": "block",
			"margin": "0px 0px 5px 0px",
			"children": "073 111-45-15"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0 0px",
			"font": "normal 300 16px/1.2 --fontFamily-sans",
			"text-align": "center",
			"color": "--light",
			"md-margin": "0px 0px 0px 0px",
			"children": "Зателефонувати"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"flex-direction": "row",
			"border-color": "--color-lightD2",
			"border-radius": "4px",
			"align-items": "center"
		}
	},
	"icon2": {
		"kind": "Icon",
		"props": {
			"category": "md",
			"icon": MdMailOutline,
			"size": "54px",
			"margin": "0px 20px 0 0px",
			"color": "#0b70de",
			"background": "--color-light",
			"padding": "12px 12px 12px 12px",
			"border-radius": "50%"
		}
	},
	"box8": {
		"kind": "Box",
		"props": {
			"min-width": "10px",
			"min-height": "10px",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "flex-start"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"href": "mailto:info@cleverusland.com",
			"color": "--light",
			"text-decoration-line": "initial",
			"font": "normal 500 22px/1.5 --fontFamily-sansHelvetica",
			"display": "block",
			"margin": "0px 0px 5px 0px",
			"children": "info@cleverusland.com"
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0 0px",
			"font": "normal 300 16px/1.2 --fontFamily-sans",
			"text-align": "center",
			"color": "--light",
			"md-margin": "0px 0px 0px 0px",
			"children": "Написати"
		}
	}
};

const Contacts = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="column" min-width="100%" width="100%" />
		<Box {...override("box")}>
			<Image {...override("image")} />
			<Box {...override("box1")}>
				<Text {...override("text")} />
				<Text {...override("text1")} />
			</Box>
		</Box>
		<Box {...override("box2")}>
			<Box {...override("box3")}>
				<Icon {...override("icon")} />
				<Box {...override("box4")}>
					<Text {...override("text2")} />
					<Text {...override("text3")} />
				</Box>
			</Box>
			<Box {...override("box5")}>
				<Icon {...override("icon1")} />
				<Box {...override("box6")}>
					<Link {...override("link")} />
					<Text {...override("text4")} />
				</Box>
			</Box>
			<Box {...override("box7")}>
				<Icon {...override("icon2")} />
				<Box {...override("box8")}>
					<Link {...override("link1")} />
					<Text {...override("text5")} />
				</Box>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Contacts, { ...Section,
	defaultProps,
	overrides
});
export default Contacts;